import React, { useEffect, useRef, useState } from "react";
import AgentSidebar from "./components/interchain-agent/AgentSidebar";
import { useAppDispatch, useAppSelector } from "./hooks/stateHooks";
import ChatComponent from "./components/interchain-agent/ChatComponent";
import {
  addSessionItem,
  toggleAgentDialog,
} from "./store/features/interchain-agent/agentSlice";
import { TxStatus } from "./types/enums";
import { resetGenericTxStatus } from "./store/features/common/commonSlice";
// import {  validateParsedTxnData} from "./hooks/interchain-agent/useTransactions";

const conversationalModelURL = process.env.REACT_APP_ConversationalModelURL || "";
const transactionalModelURL = process.env.REACT_APP_transactionalModelURL || "";

const Home = ({}) => {
  const dispatch = useAppDispatch();
  const currentSessionID = useAppSelector(
    (state) => state.agent.currentSessionID
  );
  const groupedChat = useAppSelector((state) => state.agent.groupedSessions);
  const isNew = Object.keys(groupedChat).length === 0;

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [modelType, setModelType] = useState("conversational");
  const [userInput, setUserInput] = useState("");
  const [chatInputTime, setChatInputTime] = useState<string>("");
  const [isTxn, setIsTxn] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const txStatus = useAppSelector((state) => state.common.genericTransaction);
  useEffect(() => {
    if (
      txStatus.status === TxStatus.IDLE ||
      txStatus.status === TxStatus.REJECTED
    ) {
      resetInputState();
      dispatch(resetGenericTxStatus());
    }
  }, [txStatus]);

  const dispatchSessionItem = (
    userInput: string,
    status: string,
    result: string,
    errMessage: string
  ) => {
    dispatch(
      addSessionItem({
        request: {
          [userInput]: {
            errMessage,
            result,
            status,
            date: chatInputTime,
          },
        },
        sessionID: currentSessionID,
      })
    );
  };

  const resetInputState = () => {
    setUserInput("");
    setInputDisabled(false);
  };
  const handleStopGenerating = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };
  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const toggleAgent = () => {
    dispatch(toggleAgentDialog());
  };
  const handleSubmit = async (e: React.FormEvent) => {
    setIsTxn(false);
    e.preventDefault();
    setInputDisabled(true);
    // dispatch(resetGenericTxStatus());
    const currentDate = new Date().toISOString();
    setChatInputTime(currentDate);

    if (userInput.trim() !== "") {
      // Reset the abort controller before starting a new request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController and store it in the ref
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const signal = abortController.signal;

      setInputDisabled(true);

      dispatch(
        addSessionItem({
          sessionID: currentSessionID,
          request: {
            [userInput]: {
              errMessage: "",
              result: "",
              status: "pending",
              date: currentDate,
            },
          },
        })
      );

      try {
        setUserInput("");
        const isConversational = modelType === "conversational";
        let response = await fetch(
          isConversational ? conversationalModelURL : transactionalModelURL,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(
              isConversational
                ? {
                    request: userInput,
                  }
                : {
                    request: userInput,
                    mappings: {},
                  }
            ),
            signal,
          }
        );

        if (!response.ok) {
          throw new Error("Error in API request");
        }

        let data = await response.json();

        // Polling for pending status
        while (data.status === "pending" || data.result === "") {
          await new Promise((resolve) => setTimeout(resolve, 2000));

          response = await fetch(
            isConversational ? conversationalModelURL : transactionalModelURL,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(
                isConversational
                  ? {
                      request: userInput,
                    }
                  : {
                      request: userInput,
                      mappings: {},
                    }
              ),
              signal,
            }
          );

          if (!response.ok) {
            throw new Error("Error in API request during polling");
          }

          data = await response.json();
        }

        if (data.status === "success") {
          if (data.result_type === "action") {

          } else {
            dispatch(
              addSessionItem({
                request: {
                  [userInput]: {
                    errMessage: "",
                    result: data.result,
                    status: "success",
                    date: currentDate,
                  },
                },
                sessionID: currentSessionID,
              })
            );
          }
        } else if (data.status === "error") {
          dispatch(
            addSessionItem({
              request: {
                [userInput]: {
                  errMessage: "Error processing request.",
                  result: "Error processing request.",
                  status: "failed",
                  date: currentDate,
                },
              },
              sessionID: currentSessionID,
            })
          );
        }
      } catch (error) {
        if (signal.aborted) {
          dispatch(
            addSessionItem({
              request: {
                [userInput]: {
                  errMessage: "Request cancelled",
                  result: "Request cancelled",
                  status: "failed",
                  date: currentDate,
                },
              },
              sessionID: currentSessionID,
            })
          );
        } else {
          const message =
            error instanceof Error ? error.message : `unknown error ${error}`;
          console.error("Failed to send message:", message);
          const eMsg = "Agent is offline or request failed.";

          dispatch(
            addSessionItem({
              request: {
                [userInput]: {
                  errMessage: eMsg,
                  result: eMsg,
                  status: "failed",
                  date: currentDate,
                },
              },
              sessionID: currentSessionID,
            })
          );
        }
      } finally {
        abortControllerRef.current = null;
        setInputDisabled(false);
      }
    }
  };
  const handleInputChange = (value: string) => {
    setUserInput(value);
  };
  return (
    <div className="fixed flex w-full justify-center items-center bg-[#09090ACC] z-[99999999]">
      <div
        className="bg-[#1C1C1D] h-screen  w-full   overflow-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex w-full h-full">
          {isNew ? null : (
            <AgentSidebar
              sidebarOpen={sidebarOpen}
              isLoading={inputDisabled}
              handleStopGenerating={handleStopGenerating}
            />
          )}
          <ChatComponent
            toggleSidebar={toggleSidebar}
            sidebarOpen={sidebarOpen}
            toggleAgent={toggleAgent}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            userInput={userInput}
            disabled={inputDisabled}
            isNew={isNew}
            showStopOption={!isTxn && inputDisabled}
            handleStopGenerating={handleStopGenerating}
            isTxn={isTxn}
            setModelType={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setModelType(e.target.value);
            }}
            modelType={modelType}
          />
          {/* <button onClick={handleStopGenerating}>stop...</button> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
