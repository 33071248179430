import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "./service";
import { KeyLimitPagination } from "../../../types/types";

export enum TxStatus {
    INIT = '',
    PENDING = 'pending',
    IDLE = 'idle',
    REJECTED = 'rejected',
  }
  
interface Balance {
    list: Coin[];
    status: TxStatus;
    errMsg: string;
  }

interface BankState {
    balances:{ [key: string]: Balance };
    denom: string;
    amount: string;
    errMsg: string;
    status: "pending" | "success" | "error";
}

const initialState: BankState = {
    denom: "uarka",
    amount: "0",
    errMsg: "",
    status: "pending",
    balances: {},
};

export const getBalance = createAsyncThunk(
    "bank/balance",
    async (data: { baseURL: string; address: string; denom: string }) => {
        const response = await service.balance(
            data.baseURL,
            data.address,
            data.denom
        );
        return {
            data: response.data,
        };
    }
);
export const getBalances = createAsyncThunk(
    'bank/balances',
    async (data: {
      baseURLs: string[];
      baseURL: string;
      address: string;
      chainID: string;
      pagination?: KeyLimitPagination;
    }) => {
      const response = await service.balances(
        data.baseURLs,
        data.address,
        data.chainID,
        data.pagination
      );
      return {
        chainID: data.chainID,
        data: response.data,
      };
    }
  );

export const bankSlice = createSlice({
    name: "bank",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(getBalance.pending, (state: BankState) => {
                state.status = "pending";
                state.amount = "0";
                state.errMsg = "";
            })
            .addCase(getBalance.fulfilled, (state, action) => {
                state.status = "success";
                state.amount = action.payload.data.balance.amount;
                state.errMsg = "";
            })
            .addCase(getBalance.rejected, (state: BankState, action) => {
                state.errMsg =
                    action?.error?.message || "requested rejected for unknown reason";
                state.status = "success";
                state.amount = "0";
            });
    },
});

export default bankSlice.reducer;