import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChainInfo {
    walletInfo: {
      name: string;
      isNanoLedger: boolean;
      pubKey: string;
      bech32Address: string;
      isKeystone: string;
      algo: string;
    };
    network: Network;
  }

interface WalletState {
    name: string;
    connected: boolean;
    isNanoLedger: boolean;
    pubKey: string;
    address: string;
    chainID?: string;
    fees?: string;
    networks: Record<string, ChainInfo>;
    nameToChainIDs: Record<string, string>;
}

const initialState: WalletState = {
    name: '',
    connected: false,
    isNanoLedger: false,
    pubKey: '',
    address: '',
    chainID: process.env.REACT_APP_CHAIN_ID,
    fees: process.env.FEES || '0.01uarka',
    networks: {},
    nameToChainIDs: {},
};

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setWallet: (state: any, action: PayloadAction<WalletState>) => {
            state.connected = action.payload.connected;
            state.address = action.payload.address;
            state.isNanoLedger = action.payload.isNanoLedger;
            state.name = action.payload.name;
            state.pubKey = action.payload.pubKey;
        },
        resetWallet: (state: WalletState) => {
            state.connected = false;
            state.name = '';
            state.pubKey = '';
            state.address = '';
            state.isNanoLedger = false;
        },
    },
});

export const { setWallet, resetWallet } = walletSlice.actions;

export default walletSlice.reducer;