import { configureStore } from "@reduxjs/toolkit";
import walletSlice from "./features/wallet/walletSlice";
import bankSlice from "./features/bank/bankSlice";
import agentSlice from "./features/interchain-agent/agentSlice";
import commonSlice from "./features/common/commonSlice";
import swapsSlice from "./features/swaps/swapsSlice";

export const store = configureStore({
  reducer: {
    wallet: walletSlice,
    bank: bankSlice,
    agent: agentSlice,
    common: commonSlice,
    swaps: swapsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
