import React from "react";
import "./App.css";
import Home from "./GPTHome";

function App() {
  return <Home />;
}

export default App;

