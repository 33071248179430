

import Axios, { AxiosResponse } from "axios";
import {
    addChainIDParam,
    convertPaginationToParams,
  } from '../../../utils/util';
import { axiosGetRequestWrapper } from "../../../utils/RequestWrapper";
import { KeyLimitPagination } from "../../../types/types";
  
const balanceURL = (address: string, denom: string) =>
    `/cosmos/bank/v1beta1/balances/${address}/by_denom?denom=${denom}`;
const balancesURL = '/cosmos/bank/v1beta1/balances/';
const fetchBalance = (
    baseURL: string,
    address: string,
    denom: string
): Promise<AxiosResponse> => {
    const uri = `${baseURL}${balanceURL(address, denom)}`;

    return Axios.get(uri);
};

const fetchBalances = (
    baseURLs: string[],
    address: string,
    chainID: string,
    pagination?: KeyLimitPagination
  ): Promise<AxiosResponse> => {
    let resourceEndPoint = `${balancesURL}${address}`;
    const parsed = convertPaginationToParams(pagination);
    if (parsed !== '') {
      resourceEndPoint += `?${parsed}`;
    }
    resourceEndPoint = addChainIDParam(resourceEndPoint, chainID);
  
    return axiosGetRequestWrapper(baseURLs, resourceEndPoint);
  };

const result = {
    balance: fetchBalance,
    balances: fetchBalances
};

export default result;