export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || "";
export const UPLOAD_DRAWING_API_URL =
  process.env.REACT_APP_IMAGE_SERVER_URL || "";
export const DEPLOYMENT_ID = process.env.REACT_APP_DEPLOYMENT_ID
  ? parseInt(process.env.REACT_APP_DEPLOYMENT_ID)
  : 3;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || "arka-devnet-1";
export const RPC =
  process.env.REACT_APP_RPC || "https://beta.arka.network/rpc/";
export const REST =
  process.env.REACT_APP_REST || "https://beta.arka.network/rest/";
export const GAS_FEE = 8_60_000;
export const ERR_NO_OFFLINE_AMINO_SIGNER = "no offline signer";
export const ERR_UNKNOWN = "unknown";
export const FAUCET_API_URL = process.env.REACT_APP_FAUCET_URL || "";
export const API_URL = process.env.NEXT_PUBLIC_APP_API_URI || '';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export interface ErrorResponse {
  code: number;
  message: string;
  details: any;
}
export const SUPPORTED_WALLETS = [
  {
    name: "Keplr",
    logo: "/keplr-wallet-logo.png",
  },
  {
    name: "Leap",
    logo: "/leap-wallet-logo.png",
  },
];

export const GOV_VOTE_OPTIONS: VoteOption[] = [
  {
    label: "Yes",
    value: 1,
    // selectedColor: "#2BA472",
  },
  {
    label: "No",
    value: 3,
    // selectedColor: "#D92101",
  },
  {
    label: "Abstain",
    value: 2,
    // selectedColor: "#FFC13C",
  },
  {
    label: "Veto",
    value: 4,
    // selectedColor: "#DA561E",
  },
];

export const SWAP_ROUTE_ERROR = 'Failed to fetch routes.';
export const SQUID_ID = process.env.NEXT_PUBLIC_SQUID_ID || '';
export const SQUID_CLIENT_API = 'https://api.0xsquid.com';