import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Buffer } from "buffer";
import process from "process";
import { ChainProvider } from "@cosmos-kit/react";
import { chains, assets } from "chain-registry";
import { wallets as keplr } from "@cosmos-kit/keplr";
import { wallets as leap } from "@cosmos-kit/leap";
import { Chain, AssetList } from "@chain-registry/types";
import { CHAIN_ID } from "./constants";

window.Buffer = Buffer;
window.process = process;

const arkaChain: Chain = {
  chain_name: CHAIN_ID,
  status: "live",
  network_type: "testnet",
  pretty_name: CHAIN_ID,
  chain_id: CHAIN_ID,
  bech32_prefix: "arka",
  slip44: 118,
  staking: {
    staking_tokens: [
      {
        denom: "uarka",
      },
    ],
  },
  chain_type: "cosmos",
  fees: {
    fee_tokens: [
      {
        denom: "uarka",
        fixed_min_gas_price: 0,
        low_gas_price: 0,
        average_gas_price: 0,
        high_gas_price: 0,
      },
    ],
  },
};

const arkaAssets: AssetList = {
  chain_name: CHAIN_ID,
  assets: [
    {
      denom_units: [
        {
          denom: "uarka",
          exponent: 0,
        },
        {
          denom: "arka",
          exponent: 6,
        },
      ],
      base: "uarka",
      display: "arka",
      name: "ARKA",
      symbol: "ARKA",
      type_asset: "sdk.coin",
    },
  ],
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChainProvider
        chains={[arkaChain]}
        assetLists={[arkaAssets]}
        wallets={[keplr[0], leap[0]]}
        walletConnectOptions={{
          signClient: {
            // projectId: "afafd1e39926de9a5e04223c0da73bfa", // MAIN HOSTING
            projectId: "c95d59a3bc640faaac050c0629c4aa40", // DEV HOSTING
            relayUrl: "wss://relay.walletconnect.org",
            metadata: {
              name: "ARKA SANTA",
              description:
                "Arka Santa is a fun and engaging AI agent developed by the Arka-AI team",
              url: "https://santa.arka.network/",
              icons: ["https://santa.arka.network/santa.png"],
            },
          },
        }}
        endpointOptions={{
          endpoints: {
            [CHAIN_ID]: {
              rpc: [
                process.env.REACT_APP_RPC || "https://beta.arka.network/rpc",
              ],
              rest: [
                process.env.REACT_APP_REST || "https://beta.arka.network/rest",
              ],
            },
          },
        }}
      >
        <App />
      </ChainProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
